/* You can add global styles to this file, and also import other style files */

html, body { height: 100%;
  -moz-user-select: none;
  -webkit-user-select: none; }::-webkit-scrollbar {
                              width: 0;
                              height: 0;

                            }



/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;

}

/* Handle */
/*::-webkit-scrollbar-thumb {*/
/*  background: #e589ff;*/

/*}*/

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5fb300;
}

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.mat-dialog-container {
  padding: 0!important;
  margin: 1px;
}
.mat-slide-toggle-content {
  white-space: nowrap;
overflow: visible;
  text-overflow: ellipsis;
}
.mat-slide-toggle-content {
  white-space: nowrap;
  overflow: visible!important;
  text-overflow: ellipsis;
}
.mat-slide-toggle-label {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  height: inherit;
  cursor: pointer;
  justify-content: space-around;
}
/*.mat-list-base .mat-list-item .mat-list-text, .mat-list-base .mat-list-option .mat-list-text {*/

/*  align-items: center;*/
/*}*/
@font-face {
  font-family: "batmfa";
  src: url("src/assets/fonts/batmfa__.ttf") format("opentype");
}

